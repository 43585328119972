.contrato .textCenter {
    text-align: center;
}
.contrato .strong {
    font-weight: bold;
}
.contrato .underline {
    text-decoration: underline;
}
.contrato .tab {
    margin-left: 40px;
}
.contrato .tab2 {
    margin-left: 80px;
}
.contrato .tab3 {
    margin-left: 120px;
}
.contrato .table {
    width: 100%;
}
.contrato .table, .contrato .table th, .contrato td     {
    border: 1px solid black;
}